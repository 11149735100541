frappe.provide("pcg_base.helpnotes")

var route = frappe.get_route();
const update_help_notes_debounce_fn = frappe.utils.debounce(update_help_notes, 500);

frappe.router.on("change", update_help_notes_debounce_fn);

function update_help_notes(){
  let newroute = frappe.get_route();
  if (route != newroute) {    
    $("#toolbar-help .help-entry").remove();
    addHelpEntryFromModules(newroute);
    route = newroute;
  }
}

pcg_base.helpnotes.mk_help_entry = function(label){
  let newHelpListEntry = $(
    '<a class="dropdown-item" href="/app/note/' + label + '">' + label + "</a>"
  ).addClass("help-entry");
  $("#toolbar-help").append(newHelpListEntry);
}

function addHelpEntryFromModules(route) {  
  frappe.call({ 
    method:"pcg_base.pcg_base.helpnotes.check_help_entry_routes", 
    args: {
      appspace: route[0],
      docspace: route[1],
      viewtype: route[2],
    },

    callback: (r) => {
      if (!r.exc) {
        let notes = r.message;
        notes.map(note => {
          pcg_base.helpnotes.mk_help_entry(note);    
        })
        
      }
    }
  });   
}

