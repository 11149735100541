frappe.provide("frappe.ui.misc");

function getGitChangelog(app) {
  frappe.call({
    method: "pcg_base.git.get_git_changelog",
    args: {
      app: app,
    },
    callback: function (r) {
      r.message.map((rev) => {
        let title = "<i style='font-size: 11px;'>" + rev[0] + "</i>";
        let msg = title + '<p style="margin-top: 0">' + rev[1] + "</p>";
        frappe.msgprint(msg);
      });
    },
  });
}

frappe.ui.misc.about = () => {
  if (!frappe.ui.misc.about_dialog) {
    const mainTitle = frappe.ui.misc.about.main_title || __("PCG Team");
    const mainDescription =
      frappe.ui.misc.about.main_description || __("Der PC-Gärtner ist unsere Software!");
    const mainDefaultContent =
      "<p><i class='fa fa-globe fa-fw'></i>\
		Website: <a href='https://pcgteam.eu' target='_blank'>https://pcgteam.eu</a></p>";
    const mainContent = frappe.ui.misc.about.main_content || mainDefaultContent;

    var d = new frappe.ui.Dialog({ title: __(mainTitle) });

    $(d.body).html(
      repl(
        "<div>\
		    <p>" +
          __(mainDescription) +
          "</p>" +
          mainContent +
          "<hr>\
		      <h4>Installed Apps</h4>\
		      <div id='about-app-versions'>Loading versions...</div>\
	      	<hr>\
		      <p class='text-muted'>&copy; Frappe Technologies Pvt. Ltd and contributors </p>\
	      	</div>",
        frappe.app
      )
    );

    frappe.ui.misc.about_dialog = d;

    frappe.ui.misc.about_dialog.on_page_show = function () {
      if (!frappe.versions) {
        frappe.call({
          method: "pcg_base.git.get_versions_with_git_rev",
          callback: function (r) {
            show_versions(r.message);
          },
        });
      } else {
        show_versions(frappe.versions);
      }
    };

    var show_versions = function (versions) {
      let $wrap = frappe.ui.misc.about_dialog.$body.find("#about-app-versions").empty();
      $.each(Object.keys(versions).sort(), function (i, key) {
        let v = versions[key];
        let title_link = '<a href="#"><b>' + v.title + ": </b></a>";
        let text;
        if (v.branch) {
          let title = $(title_link).click(() => getGitChangelog(key));
          text = $("<p>")
            .append(title)
            .append(
              $.format("v{0} ({1})", [
                v.branch_version || v.version,
                v.branch,
              ])
            );
        } else {
          let title = $(title_link).click(() => getGitChangelog(key));
          text = $("<p>")
            .append(title)
            .append($.format("v{0}", [v.branch_version || v.version]));

        }
        let reset_and_show_change_log = $(`<br/><a href='#'>${__('show change log')}</a><br/>`)
        reset_and_show_change_log.click(r => { frappe.ui.misc.show_last_n_change_logs(key) })
        reset_and_show_change_log.appendTo(text);
        $(text).appendTo($wrap);
      });

      frappe.versions = versions;
    };
  }

  frappe.ui.misc.about_dialog.show();
};


frappe.ui.misc.show_last_n_change_logs = async function(app){
  const result = await frappe.call("pcg_base.boot.reset_change_log", {app:app});
  if (result.message){
    frappe.boot.change_log = result.message;
    frappe.app.show_change_log();
  }
}
