// import of utils is already done in pcg_issues.js
// import {annotateField} from "pcg_issues.js";

/* This file should only ever be incuded using the doctype_js hook from hooks.py.
 * That means this file only runs on forms of the doctypes specified in that List.
 * We will set up a Conflicthandler for the first time, this file is called,
 * and append that doctype to the global scope 'conflictHandlerOn' Variable
 */

 frappe.provide("pcg_base.conflict")

var conflictHandlerOn = ["PCG Item"];

function id(a){
    return a;
}

function not(a){
    return !a;
}
 console.log("executing conflict.js")

function clearDiffViews(){
    $(".pcg_float_left").removeClass(".pcg_float_left");
    $(".pcg_conflict_diff").remove();
}

pcg_base.conflict.conflict_handler = function(frm, newDocEntry, old_doc){
    let setField = function (frm, field, value){
        if (frm.fields_dict[field].df.fieldtype === "Datetime"){
            value = value.replace(/\..*/g, '');
        } else if (frm.fields_dict[field].df.fieldtype === "Table"){
            frappe.model.set_value(frm.doctype, frm.docname, field, value).then(() =>{
                frappe.model.sync(frm.doc);
                frm.refresh_fields("referencing_issues");
            });
        }
        frm.fields_dict[field].set_value(value);
        frm.fields_dict[field].set_input(value);
        frm.fields_dict[field].set_model_value(value);
    };
    if (typeof(old_doc) !== "undefined"){
        let set_at_least_one_field = false;
        console.log("Doing the new conflict handling mehtods")
        const obj_without = function(obj){
            return function(key){
                return lodash.pickBy(obj, (_v,k) => k !== key);
            }
        }
        Object.keys(newDocEntry).forEach(field => {
            if (typeof(frm.fields_dict[field]) === "undefined"){
                return;
            }
            if(field === "modified"){
                return;
            }
            newDocEntry[field] = newDocEntry[field] === null ? undefined : newDocEntry[field];
            let newValue = newDocEntry[field];
            let this_grid = frm.fields_dict[field].grid;
            if (Array.isArray(newDocEntry[field])){
                newDocEntry[field] = newDocEntry[field].map(obj_without("modified"));
                old_doc[field] = old_doc[field].map(obj_without("modified"));
            }
            if (! lodash.isEqual(newDocEntry[field], old_doc[field])){
                console.log(field, " changed from ", old_doc[field], " to ", newDocEntry[field])
                let annotationTextGen = function(formField){
                    if(formField.df.fieldtype === "Check"){
                        return newValue ? "&#10003;" : "&#9633;"; // unicode 'check mark' and 'white square'
                    }
                    return __(newValue);
                };

                let annotationClickHandler = function(formField, _){
                    set_at_least_one_field = true;
                    if(formField.df.fieldtype === "Check"){
                        // Didn't bother finding out why negation is needed here, but it somehow is....
                        setField(frm, field, newValue);
                    } else {
                        setField(frm, field, newValue);
                    }
                };
                pcg.utils.annotateField(frm, field, annotationTextGen, annotationClickHandler);
                set_at_least_one_field = true;
            }
        })
        if (set_at_least_one_field){
            frm.dirty();
        }
        return
    }
    console.log("handling conflict of ", newDocEntry)

    // Transporting the new Doc with the socketio to avoid local get_doc ajax which makes writing the document
    let set_at_least_one_field = false;
    Object.keys(frm.fields_dict).map(frmField => {
        if(frmField === "undefined" || typeof(frmField) === "undefined" || typeof(frm.fields_dict[frmField].get_value) === "undefined"){
            return
        }
        newDocEntry[frmField] = newDocEntry[frmField] === null ? undefined : newDocEntry[frmField];
        let newValue = newDocEntry[frmField];
        let this_grid = frm.fields_dict[frmField].grid;
        if (typeof(frm.fields_dict[frmField].get_value) === "undefined"){
            console.log("get_value undefined for ", frmField, frm.fields_dict[frmField].value)
        }
        let all_considerd_empty = function(){
            let considered_empty = function(a){
                return ["  ", " ", "", null, undefined].includes(a) || (Array.isArray(a) && a.length < 1);
            }
            return Array.from(arguments).every(considered_empty)
        }
        if (!all_considerd_empty(newDocEntry[frmField], frm.fields_dict[frmField].get_value()) && newDocEntry[frmField] !== frm.fields_dict[frmField].get_value()){
            // console.log("Updating ", frmField, `because  ${newDocEntry[frmField]} is not: ${frm.fields_dict[frmField].get_value()}`)
            if (typeof(this_grid) !== "undefined" && Array.isArray(newDocEntry[frmField])){
                let new_child_entries = newDocEntry[frmField];

                // leave the following code, it may be needed once we want to give better descriptions of childtables

                // let current_child_entries = this_grid.data;
                // let current_child_entry_names = new Set ();
                // current_child_entries.forEach(r => current_child_entry_names.add(r["name"]));
                //
                // let items_to_add = new_child_entries.filter(f => ! current_child_entry_names.has(f["name"]) )
                //
                // items_to_add.forEach(item => {
                //     let new_row = this_grid.add_new_row();
                //     new_row = {...new_row, ...item}
                //     //this_grid.grid_rows[new_row.idx -1].doc.__checked = 1;
                // })

                let annotationTextGen = function(formField){
                    return __("Childtable of {0} Entries", [new_child_entries.length])
                }
                let annotationClickHandler = function(formField, _){
                    frm.doc[frmField] = new_child_entries
                    new_child_entries.forEach(new_childdoc => {
                        locals[new_childdoc.doctype][new_childdoc.name] = new_childdoc;
                    })
                    frm.fields_dict[frmField].grid.refresh()
                    set_at_least_one_field = true;
                };
                pcg.utils.annotateField(frm, frmField, annotationTextGen, annotationClickHandler);
                set_at_least_one_field = true;


            // } else if(frm.fields_dict[frmField].df.read_only){
            //     animate(frm.fields_dict[frmField].$wrapper);
            //     setField(frm, frmField, newDocEntry[frmField]);
            //     set_at_least_one_field = true;
            } else{
                let annotationTextGen = function(formField){
                    if(formField.df.fieldtype === "Check"){
                        return newValue ? "&#10003;" : "&#9633;"; // unicode 'check mark' and 'white square'
                    }
                    return __(newValue);
                };

                let annotationClickHandler = function(formField, _){
                    set_at_least_one_field = true;
                    if(formField.df.fieldtype === "Check"){
                        // Didn't bother finding out why negation is needed here, but it somehow is....
                        setField(frm, frmField, ! newValue);
                    } else {
                        setField(frm, frmField, newValue);
                    }
                };
                pcg.utils.annotateField(frm, frmField, annotationTextGen, annotationClickHandler);
            }
        }
    });
    if (set_at_least_one_field){
        frm.dirty();
    }
};


function setUpConflictHandler(frm){
    // a = frappe.model.add_child(cur_frm.doc, "Pcg Referencing Issues", "referencing_issues", 1)
    // cur_frm.refresh_field("referencing_issues")

    let requestNewDocAndHandle = function(docUpdateNotification){
        console.log("Handling doc_update for " + cur_frm.doc.doctype + " " + cur_frm.doc.name);
        let old_doc = JSON.parse(JSON.stringify(frappe.model.get_all_docs(cur_frm.doc)[0])); // json to get a deep copy
        frappe.db.get_doc(cur_frm.doctype, cur_frm.docname).then((newDocEntry) => {
            if(newDocEntry.message !== "No such Document"){
                // set the state back to the old doc, so that we can do a diff

                //frappe.model.update_in_locals(doc);
                frm.doc.modified = newDocEntry.modified;
                // Hack to prevent error message when overriding serverside changes
                // that were not yet reloaded form frappes point of view
                pcg_base.conflict.conflict_handler(cur_frm, newDocEntry, old_doc);
            }
        });
    };

    frappe.ui.form.on(frm.doctype, {
        after_save: function(frm){
            clearDiffViews();
        },
        refresh: function(frm){
            clearDiffViews();
        }

    });
    return function(docUpdateNotification){
        if (docUpdateNotification !== "" && docUpdateNotification.doctype === frm.doctype){
            requestNewDocAndHandle(docUpdateNotification);
        }
    };
}


conflictHandlerOn.map(dt => {
    frappe.ui.form.on(dt, "onload_post_render", function(frm){
        let conflictHandler = setUpConflictHandler(frm);


        frappe.realtime.on("list_update", docUpdateNotification => {
            if (docUpdateNotification["doctype"] === dt && frm.docname === docUpdateNotification["name"]){
                console.log("Handling " + docUpdateNotification.doctype + " update");
                clearDiffViews();
                conflictHandler(docUpdateNotification);
            }
        });
    });
  })
