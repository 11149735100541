
frappe.provide("pcg");
frappe.provide("pcg.utils");
frappe.provide("lodash")

// Object.defineProperty(Object.prototype, "pcg_getChained", function(){
lodash = require("lodash")

let u = pcg.utils;


const custom_css = frappe.boot.pcg_base.custom_css;
const $custom_css = $('<style type="text/css">' + custom_css + '</style>');
$('head').append($custom_css);

let add_popover_to = function(element, title, description){

    let description_popover = {
        html:true,
        placement:'right',
        trigger:'click',
        title: title,
        content: description
        }

    // heading.popover(description_popover);
    element.popover(description_popover);

    $(document).click(function(a){
        if(a.target !== element[0]){
            element.popover('hide');
        }

    });
};


function blendDoctypes(frm, detail_name_field, detail_doctype, detail_docname){
    if (u.getChained(frappe, ["blendedForms", frm.doctype, frm.docname, detail_name_field, "docname"]) == detail_docname){
    // else if (frappe.blendedForms["PCG Tag"][frm.docname]["docname"] === detail_docname){
        // console.log("Nothing changed! Only refreshing fields");
        frappe.blendedForms[frm.doctype][frm.docname][detail_name_field].reload_doc().then(() => {
            cur_frm = frm;
        });
        return;
    }

    var new_frm = {};
    var wrapper = frappe.container.add_page("BlendedDT");
    frappe.model.with_doc(detail_doctype, detail_docname, function(){
        frappe.model.with_doctype(detail_doctype, function() {
            new_frm = new frappe.ui.form.Form(detail_doctype, wrapper, false);
    		    new_frm.wrapper = new_frm.parent;
    		    new_frm.$wrapper = $(new_frm.wrapper);

            frappe.db.get_doc(detail_doctype, detail_docname).then(r => {
                // The following line is so important! Dont ask me why! I dare you to not change it
                // just setting the doc would be just too easy!
                new_frm.doc = frappe.get_doc(detail_doctype, detail_docname);
                new_frm.docname = detail_docname;
                new_frm.meta.hide_toolbar = 1;
                new_frm.setup();
                new_frm.render_form().then(() => {
                    if(! u.getChained(frappe, ["blendedForms", frm.doctype, frm.docname, detail_name_field])){
                        u.setChained(frappe, ["blendedForms", frm.doctype, frm.docname, detail_name_field])(new_frm);
                        $($(new_frm.layout_main).detach()).insertAfter($(frm.layout_main));
                    }
                    if(u.getChained(frappe, ["blendedForms", frm.doctype, frm.docname, detail_name_field, "docname"]) !== new_frm.docname){
                        let old_frm = u.getChained(frappe, ["blendedForms", frm.doctype, frm.docname, detail_name_field]);
                        $(old_frm.layout_main).remove();
                        console.log("Setting new blended doc");
                        $($(new_frm.layout_main).detach()).insertAfter($(frm.layout_main));
                        u.setChained(frappe, ["blendedForms", frm.doctype, frm.docname, detail_name_field])(new_frm);
                    }
                    cur_frm = frm;
                    $("#page-BlendedDT").remove();
                    frm.refresh();
                });
            });
        });
    });
}

function chainPromise(chain){
    console.log("resolving one");
    return chain.reduce((acc, val) => acc.then(() => val.save()), Promise.resolve());
}
function setupBlendedDoctype(frm, detail_name_field, detail_doctype, detail_docname){
    if (detail_doctype && detail_docname) {
        blendDoctypes(frm, detail_name_field, detail_doctype, detail_docname);
    }
    frm.page.set_primary_action(__("SaveXX"), function(){
        let blendedFields = u.getChained(frappe, ["blendedForms", frm.doctype, frm.docname]);
        if (typeof(blendedFields) === "object"){
            chainPromise(
                Object.keys(blendedFields).map(key => blendedFields[key])
            ).then(() => frm.save().then(() => frm.refresh()));
        // if (frappe.blendedForms && frappe.blendedForms[frm.doctype] && frappe.blendedForms[frm.doctype][frm.docname] && frappe.blendedForms[frm.doctype][frm.docname].is_dirty()){
        //     frappe.blendedForms[frm.doctype][frm.docname].save().then(() => {
        //         frm.save().then(() => {
        //             frm.refresh();
        //         });
        //     });
        } else{
            frm.save();
        }
    });
}

function setupBlendedDT(blendedFields, uiObj){
    blendedFields.map(fields => {
        console.log("Setting up fields" + fields);
        let setupF = uiObj.setup;
        uiObj.setup = function(frm){
            let detail_name_field = fields[1];
            let detail_doctype = frm.doc[fields[0]];
            let detail_docname = frm.doc[fields[1]];
            if (detail_docname && detail_doctype){
                setupBlendedDoctype(frm, detail_name_field, detail_doctype, detail_docname);
            }
            if (setupF){
                setupF(frm);
            }
        };
        uiObj[fields[1]] = function(frm){
            let detail_name_field = fields[1];
            let detail_doctype = frm.doc[fields[0]];
            let detail_docname = frm.doc[fields[1]];
            if (detail_doctype && detail_docname) {
                blendDoctypes(frm, detail_name_field, detail_doctype, detail_docname);
            }
        };
    });
    return uiObj;
}

// utility functions

// Object.defineProperty(Object.prototype, "pcg_getChained", function(){

pcg.utils = (function(){
    return Object.freeze({
        get_cached_doc: (function(){
            let cache = {};
            return function(doctype, docname, force_reload){
                if ((typeof(getChained(cache, [doctype, docname])) === "undefined") || force_reload) {
                    return new Promise((resolve, reject) => {
                        frappe.db.get_doc(doctype, docname).then(
                            function(doc){
                                            setChained(cache, [doctype, docname])(doc);
                                            resolve(doc);},
                            reason => reject(reason));
                    });
                } else {
                    return new Promise((resolve, reject) => {
                        resolve(getChained(cache, [doctype, docname]));
                    });
                }
            };
        })(),
        set_active_flag:  function(arg) {
            if (arg === "focus"){
                console.log("Focused");

            } else {
                console.log("Blurred");
            }
            return arg === "focus";
        },

        addPrimaryButtonBefore: function(d, label, cssClass, callback){
            $("<button>" + label + "</button>")
                .addClass("btn " + cssClass)
                .click(even => callback(d.get_values()))
                .insertBefore(d.$wrapper.find(".btn-primary"));
        },

        sip_answered_handler: function(answered_data){
            return undefined;
        },

        annotateField: function(frm, fieldName, annotation, onClickHandler){
            /* Annotate the FormField with given Name using the string from the given annotation
                *  (or calling the annotationGen function with the formfield to get the text for the annotation)
                * on Click it will call the given onClickHandler with the form Field passed as first argument
                * and the click target as second argument
                */
            let formField = frm.fields_dict[fieldName];
            annotation = typeof(annotation) === "function" ? annotation(formField) : annotation;
            let formField$ = formField.$wrapper;

            let relevantNode = [formField$.find(".checkbox .input-area"), formField$.find(".control-label")].find(r => r.length > 0);
            if(typeof(relevantNode) !== "undefined" && formField.get_value() !== annotation){
                // relevantNode.css("float", "left");
                let set_new_value_field = $("<div class='pcg_conflict_diff' title='" + annotation + "'>" + annotation + "</div>")
                    .click(target => {
                        formField$.find(".pcg_conflict_diff").remove();
                        relevantNode.children().first().css("float", "");
                        return onClickHandler(formField, target);
                    });
                    set_new_value_field.insertAfter(relevantNode);

                }
        },

        getChained: function (obj, path){
            let that = obj;
            let args = path; // [].slice.call(arguments); // converts arguments object to array
            return args.reduce((acc, arg) => typeof(acc) === "object" ? acc[arg] : undefined, that);
        },

        // Object.defineProperty(Object.prototype, "pcg_setChained", function(){
        setChained: function (obj, path){
            let that = obj;
            let args = path; // [].slice.call(arguments); // converts arguments object to array
            return function(value){
                args.reduce((acc, elem, idx) => {
                    if (idx == args.length - 1){
                        acc[elem] = value;
                        return acc[elem];
                    } else if( typeof(acc[elem]) === "object"){
                        return acc[elem];
                    } else {
                        acc[elem] = {};
                        return acc[elem];
                        }
                }, that);
                return that;
            };
        },

        dt_blending: {setupBlendedDT: setupBlendedDT},
        add_popover_to: add_popover_to,

        toggleable_field_tooltip: function (frm, field, additional_description){
            const fieldname = field.df.fieldname;
            const fieldtype = field.df.fieldtype;
            let parent = undefined;
            let heading =undefined;
            let help_icon = $(frappe.utils.icon("fa-question-circle")).addClass("pcg_help_icon");
            let description = __(field.df.description)            
            let hide_description = true;
            if (! ["", null, undefined].includes(additional_description)) {
                description = __(additional_description);
                hide_description = false;
            }
            // if (["", undefined, null].includes(description)){
            //     description = __("No Description found!")
            // }
            //console.log("TOGGABLE: " + fieldtype)
            let add_popover_to_h = function(element){
                add_popover_to(element, __(field.df.label) || '', __(description))
            }
            switch(fieldtype){
                case "Section Break":
                    parent = $(frm.fields_dict[fieldname].wrapper);
                    heading = parent;                 
                    help_icon.click(r => false)
                    heading.append(help_icon);
                    add_popover_to_h(help_icon);
                    if (hide_description){
                        parent.find(".form-section-description").hide()
                    }
                    break;

                case "Column Break":
                    parent = $(field.wrapper);
                    help_icon.addClass("pcg_hi_column")
                    parent.prepend(help_icon);
                    add_popover_to_h(help_icon);
                    break;

                case "Button":
                    parent = $(frm.fields_dict[fieldname].wrapper);
                    heading = parent.find(".control-input")
                    heading.append(help_icon);
                    help_icon.click(()=> false); // prevent button activation when clicking the icon
                    add_popover_to_h(help_icon);
                    if (hide_description){
                        parent.find(".help-box").hide();
                    }

                    break;
                case "Table":
                    parent = frm.fields_dict[fieldname].$wrapper;
                    heading = parent.find(".control-label");
                    help_icon.insertAfter(heading);
                    if (hide_description){
                        parent.find(".form-section-description, .help-box, .grid-description").hide()
                    }
                    add_popover_to_h(heading)
                    add_popover_to_h(help_icon)
                    break;

                    case "Check":
                        parent = frm.fields_dict[fieldname].$wrapper;
                        heading = parent.find(".checkbox .label-area");
                        help_icon.insertAfter(heading);
                        help_icon.click(()=> false); // prevent checkbox toggle when clicking the icon
                        if (hide_description){
                            parent.find(".form-section-description, .help-box, .grid-description").hide()
                        }
                        add_popover_to_h(heading)
                        add_popover_to_h(help_icon)
                        break;

                default:
                    parent = frm.fields_dict[fieldname].$wrapper;
                    if (parent == undefined) break;
                    heading = parent.find(".control-label");
                    help_icon.insertAfter(heading);
                    if (hide_description){
                        parent.find(".form-section-description, .help-box, .grid-description").hide()
                    }
                    //console.log("Adding description ", description, " for field ", fieldname);
                    add_popover_to_h(heading)
                    add_popover_to_h(help_icon)

            }
        },
    });
})();

pcg = Object.freeze(pcg);


const orig_script_manager = frappe.ui.form.ScriptManager;

frappe.ui.form.ScriptManager =  function(opts){
    console.log("Call coinstructor")
    const script_manager = new orig_script_manager(opts)
    const orig_trigger = script_manager.trigger;

    script_manager.trigger = function(event_name, doctype, name){
        const doctype_additional_descriptions = frappe.boot.pcg_base.description_tooltips[this.frm.doctype] || {}
        if (event_name === "refresh"){ // onload would be nicer, as it does not reexecute on "reload" but childtable formfields are not rendered at that point... :(
            $(".pcg_help_icon").remove();
            if (typeof(this.frm) !== "undefined"){
                this.frm.fields.forEach(field => {
                    const additional_description = (doctype_additional_descriptions[field.df.fieldname] || [null])[0];
                    if((! ["", undefined, null].includes(field.df.description)) || (! ["", undefined, null].includes(additional_description))){
                        pcg.utils.toggleable_field_tooltip(this.frm, field, additional_description);
                    }
                })
            }
            //console.log("executing onlode code")
        }
        return orig_trigger.apply(this, [event_name, doctype, name]);
    }
    return script_manager;
}
